import {ZahlungState} from '../../interfaces/zahlung-state.interface';
import {createReducer, on} from '@ngrx/store';
import {ZahlungActions} from '../actions/zahlung.actions';
import * as moment from 'moment';
import {formatDate} from '@angular/common';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';
import {UtilityWidgetZahlungData} from '../../interfaces/utility-widget-zahlung-data.interface';
import {WidgetHeaderData} from '../../modules/widget/widget-header/widget-header-data';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';


export const initialZahlungState: ZahlungState = {
  allZahlungenRead: false,
  zahlungDtos: [],
  filterStatusOpen: true,
  filterSuche: '',
  zahlungenCounted: 0,
  pageSize: 50,
  pageIndex: 0,
  badgeValues: [],
  checkedZahlungDtos: [],
  reloadZahlungen: false,
  startTransaction: false,
  closeEditPage: false,
  saveActionSuccessedOrNotNeeded: false,
  suggestedIbansBankInfo: [],
  bankinfoBank: '',
  bankinfoBic: '',
  bankinfoLoading: false,
  zahlungDeleteDialogData: {
    isDeleteDialogOpen: false,
  },
  utilityWidgetData: {
    widgetHeaderDataCreated: false,
    initialWidgetDataLoading: true,
    widgetData: [],
  },
};

export const zahlungReducer = createReducer(
  initialZahlungState,

  /*
   * INFO: Zahlung Erstellen Reducers
   */

  on(
    ZahlungActions.createZahlung,
    (state, action) => ({
      ...state,
      startTransaction: action.startTransaction,
      closeEditPage: !action.startTransaction,
      saveActionSuccessedOrNotNeeded: false,
    })
  ),

  on(
    ZahlungActions.createZahlungSuccess,
    (state, action) => ({
      ...state,
      zahlungDto: action.zahlungDto,
      saveActionSuccessedOrNotNeeded: true,
    })
  ),

  /*
   * INFO: Zahlung Aktualisieren Reducers
   */

  on(
    ZahlungActions.updateZahlung,
    (state, action) => ({
      ...state,
      startTransaction: action.startTransaction,
      closeEditPage: !action.startTransaction,
      saveActionSuccessedOrNotNeeded: false,
    })
  ),

  on(
    ZahlungActions.updateZahlungSuccess,
    (state) => ({
      ...state,
      saveActionSuccessedOrNotNeeded: true,
    })
  ),

  on(
    ZahlungActions.updateZahlungNotNeeded,
    (state, action) => ({
      ...state,
      startTransaction: action.startTransaction,
      closeEditPage: !action.startTransaction,
      saveActionSuccessedOrNotNeeded: true,
    })
  ),

  /*
   * INFO: Zahlung Laden Reducers
   */

  on(
    ZahlungActions.getZahlungSuccess,
    (state, action) => ({
      ...state,
      zahlungDto: action.zahlungDto,
      actualZahlungDto: action.zahlungDto,
    })
  ),

  on(
    ZahlungActions.readZahlungen,
    (state, action) => ({
      ...state,
      filterStatusOpen: !action.filterDto.angewiesen,
      pageIndex: action.pageIndex,
    })),

  on(
    ZahlungActions.readZahlungenSuccess,
    (state, action) => ({
      ...state,
      allZahlungenReadRead: true,
      zahlungDtos: action.zahlungDtos,
    })),

  on(
    ZahlungActions.sortingChanged,
    (state, action) => ({
      ...state,
      sorting: action.sortingEnum,
    })),

  on(
    ZahlungActions.pageIndexChanged,
    (state, action) => ({
      ...state,
      pageIndex: action.pageIndex,
    })),

  on(
    ZahlungActions.searchValueChanged,
    (state, action) => {
      let searchValue = action.searchValue;

      if (action.searchValue === undefined) {
        searchValue = '';
      }

      return {
        ...state,
        filterSuche: searchValue,
      };
    }),

  on(
    ZahlungActions.updateFilterStatusOpen,
    (state, action) => ({
      ...state,
      filterStatusOpen: action.filterStatusOpen,
    })),

  on(
    ZahlungActions.reloadZahlungDtos,
    (state) => ({
      ...state,
      reloadZahlungen: true
    })),

  on(
    ZahlungActions.reloadZahlungDtosTriggered,
    (state) => ({
      ...state,
      reloadZahlungen: false
    })),

  /*
   * INFO: Zahlung Löschen Reducers
   */

  on(
    ZahlungActions.openZahlungDeleteDialog,
    (state, action) => ({
      ...state,
      zahlungDeleteDialogData: {
        isDeleteDialogOpen: true,
        inhaberId: action.inhaberId,
        zahlungId: action.zahlungId,
      }
    })),

  on(
    ZahlungActions.openZahlungDeleteEmbedded,
    (state, action) => ({
      ...state,
      zahlungDeleteDialogData: {
        isDeleteDialogOpen: true,
        inhaberId: action.inhaberId,
        zahlungId: action.zahlungId,
      }
    })),

  on(
    ZahlungActions.closeZahlungDeleteDialog,
    (state) => ({
      ...state,
      zahlungDeleteDialogData: {
        ...initialZahlungState.zahlungDeleteDialogData,
      }
    })),

  on(
    ZahlungActions.updateZahlungDeleteDialogData,
    (state, action) => ({
      ...state,
      zahlungDeleteDialogData: {
        ...state.zahlungDeleteDialogData,
        inhaberId: action.inhaberId,
        zahlungId: action.zahlungId,
      }
    })),

  on(
    ZahlungActions.zahlungDeleteResponse,
    (state, action) => {
      return {
        ...state,
        zahlungDeleteDialogData: {
          ...state.zahlungDeleteDialogData,
          deleteSuccessful: action.success,
          deleteFailureInfo: action.zahlungDeleteFailureInfo,
        }
      };
    }),

  /*
   * INFO: Zahlung Anzahl Laden Reducers
   */

  on(
    ZahlungActions.updateZahlungCounter,
    (state, action) => ({
      ...state,
      zahlungenCounted: action.counted,
    })),


  /*
   * INFO: Zahlung Table Reducers
   */

  on(
    ZahlungActions.addCheckedZahlungDto,
    (state, action) => {
      if (state.checkedZahlungDtos.findIndex(zahlungDto => zahlungDto.id === action.checkedZahlungDto.id) !== -1) {
        return {...state};
      } else {
        return {
          ...state,
          checkedZahlungDtos: [...state.checkedZahlungDtos, action.checkedZahlungDto]
        };
      }
    }),

  on(
    ZahlungActions.removeCheckedZahlungDto,
    (state, action) => {
      const checkedZahlungDtos = [...state.checkedZahlungDtos];
      const deleteIndex = checkedZahlungDtos.findIndex(zahlungDto => zahlungDto.id === action.checkedZahlungDto.id);
      if (deleteIndex !== -1) {
        checkedZahlungDtos.splice(deleteIndex, 1);

        return {
          ...state,
          checkedZahlungDtos: checkedZahlungDtos,
        };
      }

      return {
        ...state,
      };
    }),

  on(
    InhaberEntitiesActions.setCurrentInhaberIdFromURL,
    (state, action) => {
      // INFO: Wenn der aktuelle Inhaber nicht dem Inhaber des ausgewählten Kontos entspricht, wird die Vorauswahl des Kontos zurückgesetzt.
      const firstCheckedZahlungInhaberId = state.checkedZahlungDtos.at(0)?.inhaberId;
      if (!firstCheckedZahlungInhaberId || action.currentInhaberId === firstCheckedZahlungInhaberId) {
        return state;
      }
      return {
        ...state,
        checkedZahlungDtos: [],
      };
    }),

  on(
    ZahlungActions.resetCheckedZahlungDtos,
    (state) => ({
      ...state,
      checkedZahlungDtos: [],
    })),


  /*
   * INFO: Zahlung Edit Form Reducers
   */

  on(
    ZahlungActions.resetActualZahlung,
    (state) => ({
      ...state,
      zahlungDto: undefined,
      actualZahlungDto: undefined,
    })
  ),

  on(
    ZahlungActions.setActualZahlungNull,
    (state) => ({
      ...state,
      zahlungDto: null,
    })
  ),

  on(
    ZahlungActions.setToActualZahlung,
    (state) => ({
      ...state,
      zahlungDto: state.actualZahlungDto,
    })
  ),

  on(
    ZahlungActions.resetEditPageOnInit,
    (state) => ({
      ...state,
      startTransaction: false,
      closeEditPage: false,
      saveActionSuccessedOrNotNeeded: false,
      suggestedIbansBankInfo: [],
      belegSuggestedIbans: undefined,
      suggestedPurpose: undefined,
      bankinfoBank: '',
      bankinfoBic: '',
    })
  ),

  on(
    ZahlungActions.initialAssignedBelegLoaded,
    (state, action) => ({
      ...state,
      assignedBelegDto: action.belegDto,
    })),

  on(
    ZahlungActions.assignBelegToZahlung,
    (state, action) => {
      const partner = action.belegDto.partner;

      const empfaenger = {
        id: partner?.id,
        bezeichnung: partner?.bezeichnung,
      };

      const dateFaelligkeit = moment(action.belegDto.faelligkeitsdatum);
      const dateNow = formatDate(moment(new Date()).toString(), 'YYYY-MM-dd', 'de-DE');

      let faelligkeitsdatum: string | undefined = undefined;
      if (dateFaelligkeit.isSame(dateNow) || dateFaelligkeit.isAfter(dateNow)) {
        faelligkeitsdatum = action.belegDto.faelligkeitsdatum;
      }

      return {
        ...state,
        suggestedIbansBankInfo: [],
        belegSuggestedIbans: undefined,
        suggestedPurpose: undefined,
        bankinfoBank: action.belegDto.bankbezeichnung || '',
        bankinfoBic: action.belegDto.bic || '',
        beleg: action.belegDto,

        zahlungDto: {
          ...state.zahlungDto,
          belegId: action.belegDto.id,
          inhaberId: action.belegDto.inhaberId,
          empfaenger,
          iban: action.belegDto.iban,
          bic: action.belegDto.bic,
          bankbezeichnung: action.belegDto.bankbezeichnung,
          betrag: action.belegDto.betrag,
          verwendungszweck: action.belegDto.verwendungszweck,
          faelligkeitsdatum,
          offen: true,
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.changedFormValues,
    (state, action) => {
      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          empfaenger: action.empfaengerDto,
          iban: action.iban || '',
          betrag: action.betrag || 0,
          verwendungszweck: action.verwendungszweck,
          faelligkeitsdatum: action.faelligkeitsdatum,
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.changedFormEmpfaenger,
    (state, action) => {
      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          empfaenger: action.empfaengerDto
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.changedFormIban,
    (state, action) => {
      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          iban: action.iban
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.changedFormBetrag,
    (state, action) => {
      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          betrag: action.betrag
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.changedFormVerwendungszweck,
    (state, action) => {
      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          verwendungszweck: action.verwendungszweck
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.changedFormFaelligkeitsdatum,
    (state, action) => {
      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          faelligkeitsdatum: action.faelligkeitsdatum
        } as ZahlungDTO
      };
    }),

  on(
    ZahlungActions.bankInfoLoaded,
    (state, action) => {

      let bankbezeichnung: string | undefined = action.bank;
      let bic: string | undefined = action.bic;

      if (action.iban === state.actualZahlungDto?.iban) {
        bankbezeichnung = state.actualZahlungDto?.bankbezeichnung;
        bic = state.actualZahlungDto?.bic;
      }

      return {
        ...state,
        zahlungDto: {
          ...state.zahlungDto,
          bankbezeichnung,
          bic,
        } as ZahlungDTO,
        bankinfoBank: action.bank,
        bankinfoBic: action.bic,
        bankinfoLoading: false,
      };
    }),

  on(
    ZahlungActions.readBank,
    (state, action) => {
      return {
        ...state,
        bankinfoLoading: true,
      };
    }),

  on(
    ZahlungActions.activatePopupMode,
    (state) => ({
      ...state,
      popupMode: true,
    })),

  on(
    ZahlungActions.removeBelegAssociation,
    (state) => {
      const newState = {...state};
      const newZahlung = {
        ...newState.zahlungDto,
        belegId: undefined,
        empfaenger: '',
        iban: '',
        betrag: null,
        verwendungszweck: '',
        faelligkeitsdatum: '',
        bic: '',
        bankbezeichnung: '',
      } as unknown as ZahlungDTO;
      newState.zahlungDto = newZahlung;
      newState.bankinfoBic = '';
      newState.bankinfoBank = '';
      newState.assignedBelegDto = undefined;

      return newState;
    }),

  on(
    ZahlungActions.updateZahlungAngewiesen,
    (state) => {
      const zahlungDto = {
        ...state.zahlungDto,
        angewiesen: true,
        offen: false,
      } as ZahlungDTO;

      return {
        ...state,
        zahlungDto,
      };
    }),

  on(
    ZahlungActions.getBelegSuggestionsSuccess,
    (state, action) => {
      const purposeData = action.suggestionsDto.verwendungszweck;
      if (purposeData && (purposeData.suggested || purposeData.candidates)) {

        const purposeSuggested = purposeData.suggested;
        const purposeCandidates = purposeData.candidates;

        let purpose = '';
        if (purposeSuggested) {
          purpose = purposeSuggested;
        } else if (purposeCandidates && purposeCandidates.length > 0) {
          purpose = purposeCandidates[0];
        }

        return {
          ...state,
          suggestedPurpose: purpose,
          belegSuggestedIbans: action.suggestionsDto.iban,
        };
      }

      return {
        ...state,
        belegSuggestedIbans: action.suggestionsDto.iban,
      };
    },
  ),

  on(
    ZahlungActions.loadBankinfoFromSuggestionSuccess,
    (state, action) => {
      const currentSuggestedIbansBankInfo = [...state.suggestedIbansBankInfo];
      if (!currentSuggestedIbansBankInfo.find(ibansSuggestion => ibansSuggestion.iban === action.iban)) {
        return {
          ...state,
          suggestedIbansBankInfo: [
            ...state.suggestedIbansBankInfo,
            {
              bank: action.bank,
              iban: action.iban,
            }
          ],
        };
      }

      return {
        ...state,
      };
    }),

  /*
   * INFO: Zahlung Utility Reducers
   */

  on(
    ZahlungActions.addBadgeValue,
    (state, action) => {
      /*
       * INFO: Für jeden Inhaber wird je ein BadgeValue auf die Liste gesetzt.
       * Wird die Liste nun aktualisiert und es existiert bereits ein BadgeValue für den Inhaber,
       * so muss das alte entfernt und durch das neue ersetzt werden.
       */
      const badgeValues = [...state.badgeValues];
      const deleteIndex = badgeValues.findIndex(badgeValue => badgeValue.inhaberId === action.inhaberId);
      if (deleteIndex !== -1) {
        badgeValues.splice(deleteIndex, 1);

        return {
          ...state,
          badgeValues: [...badgeValues, {inhaberId: action.inhaberId, countedBadgeValue: action.badgeValue}],
        };
      }

      return {
        ...state,
        badgeValues: [...state.badgeValues, {inhaberId: action.inhaberId, countedBadgeValue: action.badgeValue}],
      };
    }),

  /*
   * INFO: Zahlung Utility-Widget Reducers
   */

  on(
    ZahlungActions.widgetHeaderDataCreated,
    (state, action) => ({
      ...state,
      utilityWidgetData: {
        ...state.utilityWidgetData,
        widgetHeaderData: action.widgetHeaderData,
        widgetHeaderDataCreated: true,
      },
    })
  ),

  /**
   * Legt initial je ein UtilityWidgetData je Inhaber an.
   * Aktualisiert den Status (ZahlungenOverdueLoading) für ein UtilityWidgetData
   * je nachdem ob das Laden neuer Zahlung angestoßen wurde.
   */
  on(
    ZahlungActions.readZahlungenUtilityWidget,
    (state, action) => {

      // INFO: Prüfen, ob überhaupt schon UtilityWidgetDatas existieren
      if (state.utilityWidgetData) {

        const widgetDataCpy: UtilityWidgetZahlungData[] = [...state.utilityWidgetData.widgetData!];

        // INFO: Prüfen, ob ein UtilityWidgetData für den Inhaber existiert
        const currentWidgetData: UtilityWidgetZahlungData | undefined = widgetDataCpy.find(data => {
          return data.inhaberId === action.inhaberDto.id;
        });

        const areOverdueZahlungenLoading = moment(new Date().toDateString()).isAfter(moment(action.maturityTo));

        if (currentWidgetData) {
          /*
           * INFO: Wenn ein UtilityWidgetData zu dem Inhaber existiert,
           * dann dieses kopieren ZahlungenOverdueLoading auf true setzen.
           */

          const currentWidgetDataIndex = widgetDataCpy.findIndex(data => data.inhaberId === currentWidgetData.inhaberId);

          const newWidgetData = widgetDataCpy.filter(data => data.inhaberId !== currentWidgetData.inhaberId);

          newWidgetData.splice(currentWidgetDataIndex, 0, {
            inhaberId: currentWidgetData.inhaberId,
            zahlungenOverdueData: currentWidgetData.zahlungenOverdueData,
            zahlungenNextSevenDaysData: currentWidgetData.zahlungenNextSevenDaysData,
            zahlungenOverdueLoading: areOverdueZahlungenLoading,
            zahlungenNextSevenDaysLoading: !areOverdueZahlungenLoading,
          });

          return {
            ...state,
            utilityWidgetData: {
              ...state.utilityWidgetData,
              widgetData: newWidgetData,
            },
          };

        } else {
          // INFO: Es ist noch kein UtilityWidgetData zu dem Inhaber vorhanden, deswegen dieses erstellen

          const newWidgetDatas: UtilityWidgetZahlungData[] = [
            ...state.utilityWidgetData.widgetData!,
            {
              inhaberId: action.inhaberDto.id,
              zahlungenOverdueLoading: areOverdueZahlungenLoading,
              zahlungenNextSevenDaysLoading: !areOverdueZahlungenLoading,
            },
          ];

          return {
            ...state,
            utilityWidgetData: {
              ...state.utilityWidgetData,
              widgetData: newWidgetDatas,
            },
          };
        }

      } else {
        // INFO: Es gibt insgesamt noch keine UtilityWidgetDatas, deswegen neu anlegen

        return {
          ...state,
          widgetData: [{
            inhaberId: action.inhaberDto.id,
            zahlungenOverdueLoading: true,
            zahlungenNextSevenDaysLoading: true,
          }],
        };
      }
    }
  ),

  /**
   * Das Aktualisieren des WidgetHeaderData für einen Inhaber.
   */
  on(
    ZahlungActions.widgetHeaderDataUpdated,
    (state, action) => {
      // INFO: Daten im Widget Header aktualisieren
      const widgetHeaderDataCpy: WidgetHeaderData = {...state.utilityWidgetData.widgetHeaderData!};

      const currentInhaberId = action.inhaberId;

      const currentWidgetInhaberDataIndex = widgetHeaderDataCpy.widgetInhaberDatas?.findIndex(data => {
        return data.inhaber.id === currentInhaberId;
      })!;
      if (currentWidgetInhaberDataIndex > -1) {

        // INFO: Es muss bereits ein WidgetInhaberData existiert, dieses kopieren und aktualisieren
        const currentWidgetInhaberData = widgetHeaderDataCpy.widgetInhaberDatas?.find(data => {
          return data.inhaber.id === currentInhaberId;
        })!;

        const widgetInhaberDatasCopy = [...widgetHeaderDataCpy.widgetInhaberDatas!];

        const currentWidgetData = state.utilityWidgetData.widgetData!.find(data => data.inhaberId === action.inhaberId)!;
        const overdueZahlungenExists = currentWidgetData.zahlungenOverdueData
          && (currentWidgetData.zahlungenOverdueData.zahlungDtos?.length || 0) > 0;

        const nextSevenDaysZahlungenExists = currentWidgetData.zahlungenNextSevenDaysData
          && (currentWidgetData.zahlungenNextSevenDaysData.zahlungDtos?.length || 0) > 0;

        widgetInhaberDatasCopy.splice(currentWidgetInhaberDataIndex, 1, {
          ...currentWidgetInhaberData,
          todoExists: (overdueZahlungenExists || nextSevenDaysZahlungenExists) || false,
        });

        // INFO: Anschließend das WidgetHeaderData aktualisieren
        widgetHeaderDataCpy.widgetInhaberDatas = widgetInhaberDatasCopy;
      }

      return {
        ...state,
        utilityWidgetData: {
          ...state.utilityWidgetData,
          widgetHeaderData: widgetHeaderDataCpy,
        },
      };
    }
  ),

  /**
   * Das Aktualisieren der Zahlungen für einen Inhaber.
   */
  on(
    ZahlungActions.readZahlungenUtilityWidgetSuccess,
    (state, action) => {
      // INFO: Listen mit Zahlungsdaten aktualisieren

      const currentInhaberId = action.inhaberId;
      const widgetDataCpy: UtilityWidgetZahlungData[] = [...state.utilityWidgetData.widgetData!];

      // INFO: An dieser Stelle muss ein UtilityWidgetData für den Inhaber existieren (Siehe loadZahlungenOverdue)
      const widgetDataIndex = widgetDataCpy.findIndex(data => data.inhaberId === currentInhaberId);

      if (widgetDataIndex > -1) {

        // INFO: Es muss bereits ein UtilityWidgetData für den Inhaber existieren, dieses kopieren und aktualisieren
        const currentWidgetData: UtilityWidgetZahlungData = widgetDataCpy.find(data => data.inhaberId === currentInhaberId)!;

        const areOverdueZahlungenLoaded = moment(new Date().toDateString()).isAfter(moment(action.maturity));

        const newUtilityWidgetData: UtilityWidgetZahlungData = {
          ...currentWidgetData,
        };

        if (areOverdueZahlungenLoaded) {
          newUtilityWidgetData.zahlungenOverdueData = {
            ...currentWidgetData.zahlungenOverdueData!,
            zahlungDtos: action.zahlungDtos,
            pageIndex: action.pageIndex,
            pageSize: action.pageSize,
          };
          newUtilityWidgetData.zahlungenOverdueLoading = false;

        } else {
          newUtilityWidgetData.zahlungenNextSevenDaysData = {
            ...currentWidgetData.zahlungenNextSevenDaysData!,
            zahlungDtos: action.zahlungDtos,
            pageIndex: action.pageIndex,
            pageSize: action.pageSize,
          };
          newUtilityWidgetData.zahlungenNextSevenDaysLoading = false;
        }

        widgetDataCpy.splice(widgetDataIndex, 1, newUtilityWidgetData);
      }

      return {
        ...state,
        utilityWidgetData: {
          ...state.utilityWidgetData,
          widgetData: widgetDataCpy,
          forceReload: false,
        },
      };
    }
  ),

  /**
   * Aktualisiert den Count der Zahlungen für einen Inhaber.
   */
  on(
    ZahlungActions.zahlungCountSuccess,
    (state, action) => {

      const currentInhaberId = action.inhaberId;
      const countedZahlungen = action.responseDto.count;
      const widgetDataCpy: UtilityWidgetZahlungData[] = [...state.utilityWidgetData.widgetData!];

      // INFO: An dieser Stelle muss ein UtilityWidgetData für den Inhaber existieren (Siehe loadZahlungenOverdue)
      const widgetDataIndex = widgetDataCpy.findIndex(data => {
        return data.inhaberId === currentInhaberId;
      });

      if (widgetDataIndex > -1) {
        // INFO: UtilityWidgetData existiert für den Inhaber, deswegen aktualisieren
        const currentWidgetData: UtilityWidgetZahlungData = widgetDataCpy.find(data => data.inhaberId === currentInhaberId)!;

        const areOverdueZahlungenLoaded = moment(new Date().toDateString()).isAfter(moment(action.maturity));

        const newUtilityWidgetData: UtilityWidgetZahlungData = {
          ...currentWidgetData,
        };

        if (areOverdueZahlungenLoaded) {
          newUtilityWidgetData.zahlungenOverdueData = {
            ...currentWidgetData.zahlungenOverdueData!,
            countedZahlungen: countedZahlungen,
          };
        } else {
          newUtilityWidgetData.zahlungenNextSevenDaysData = {
            ...currentWidgetData.zahlungenNextSevenDaysData!,
            countedZahlungen: countedZahlungen,
          };
        }

        widgetDataCpy.splice(widgetDataIndex, 1, newUtilityWidgetData);

        return {
          ...state,
          utilityWidgetData: {
            ...state.utilityWidgetData,
            widgetData: widgetDataCpy,
          },
        };
      }

      return {
        ...state,
      };
    }
  ),

  on(
    ZahlungActions.forceReloadUtilityWidget,
    (state) => ({
      ...state,
      utilityWidgetData: {
        ...state.utilityWidgetData,
        forceReload: true,
      },
    })
  ),
);
